

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.main{overflow:hidden}
